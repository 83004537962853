import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { PrescriptionModel } from '@/models/prescriptions-model'
import { langues } from '../../../../views/Patients/Dossier/Constants'
import { PatientService } from '@/services/patient-service'
import { DossierAssurance } from '../../../../views/Patients/Static'
import DocPreviewBase from '../DocPreviewBase.vue'
import AddressBloc from '../AddressBloc.vue'

@Component({
  components: {
    DocPreviewBase,
    AddressBloc
  }
})
export default class PrescriptionReport extends Vue {
  @Prop({ default: {} })
  public dossierAssurance!: DossierAssurance

  @Prop({ default: [] })
  public reseauPro!: any[]

  @Prop()
  public datePrescription!: string

  private subscriptions: Subscription[] = []
  private userService = AuthService.getInstance()
  private prescriptionService = PatientPrescriptionService.getInstance()
  private patientService = PatientService.getInstance()
  private selectedDossier: dossierPatientModel| any = {}
  public expectedDischargeDate = ''
  public consultationPlace = ''
  public treatingDoctor = ''
  public diagnostic = ''
  public oralAntidiabeticDrugs = ''
  public insulin = ''
  public otherDrugs = ''
  public weightReductionText = ''
  public idealWeightText = ''
  public patientFullName = ''
  public userFullName = ''
  public users: AppUser[] = []
  public errorMessages: string[] = []
  public medecinId = ''
  public medecin: any = null
  public medecinTraitantId = ''
  public medecinTraitant: any = null
  public prescription: PrescriptionModel | any = {}
  public nurseAdive = false
  public footCare = false
  public dieticianAdvice = false
  public weightReduction = false
  public idealWeight = false
  public langues = langues
  public insurance = ''
  public assurances: any[] = []
  public addressBloc: any[] = []
  public loaded = false

  public mounted () {
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      this.selectedDossier = JSON.parse(cachedDossier!)
      this.patientFullName = this.selectedDossier.fullName
    } else {
      this.$router.push({ name: 'patient' })
    }
    this.subscriptions.push(this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) {
        this.patientService.getAllAssurance()
      }
      this.getDossierAssurance()
    }))
    this.subscriptions.push(this.prescriptionService.prescriptionSelected$.subscribe(selectedPrescription => {
      this.prescription = selectedPrescription
      if (this.prescription.prestationTypeIds.includes(1)) {
        this.dieticianAdvice = true
      }
      if (this.prescription.prestationTypeIds.includes(2)) {
        this.nurseAdive = true
      }
      if (this.prescription.prestationTypeIds.includes(4)) {
        this.nurseAdive = true
      }
      if (this.prescription.prestationTypeIds.includes(5)) {
        this.nurseAdive = true
      }
      if (this.prescription.prestationTypeIds.includes(3)) {
        this.footCare = true
      }
    }))
    this.userFullName = this.userService.fullName
  }

  public getDossierAssurance () {
    if (this.selectedDossier?.guid) {
      this.mapDossierAssuranceResponse(this.dossierAssurance)
    }
    this.loaded = true
  }

  private mapDossierAssuranceResponse (dossierResponse: DossierAssurance) {
    this.dossierAssurance = dossierResponse
    this.mapInsuranceName()
  }

  private mapInsuranceName () {
    if (this.prescription?.prestationTypeIds) {
      this.insurance = this.assurances.find(x => x.id === this.dossierAssurance.assuranceId)?.assuranceName
    }
  }

  get formattedOtherDrugs () {
    return Commons.newlines2br(this.otherDrugs)
  }

  get formattedConsultationPlace () {
    return Commons.newlines2br(this.consultationPlace)
  }

  get formattedDiagnostic () {
    return Commons.newlines2br(this.diagnostic)
  }

  get formattedOralAntidiabeticDrugs () {
    return Commons.newlines2br(this.oralAntidiabeticDrugs)
  }

  get formattedInsulin () {
    return Commons.newlines2br(this.insulin)
  }

  get formattedExpectedDischargeDate () {
    return Commons.newlines2br(this.expectedDischargeDate)
  }

  get formattedWeightReductionText () {
    return Commons.newlines2br(this.weightReductionText)
  }

  get formattedIdealWeightText () {
    return Commons.newlines2br(this.idealWeightText)
  }

  public doctorChanged (doctorId: string) {
    this.medecin = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctorId)
    this.$emit('doctorChanged', this.medecin?.fullname)
  }

  public treatingDoctorChanged (doctorId: string) {
    this.medecinTraitant = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === doctorId)
  }

  get languages () {
    if (this.selectedDossier?.languagesEnumIdList) {
      this.selectedDossier.languagesEnumIdList = Commons.transformLanguages(this.selectedDossier.languagesEnumIdList)
      return this.langues.filter(l => this.selectedDossier.languagesEnumIdList.some(x => Number(x) === Number(l.value))).map(a => a.text).join(", ")
    }
    return ''
  }

  get theDate () {
    return this.datePrescription ? new Date(this.datePrescription) : new Date()
  }
}
